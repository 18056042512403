import { APP_INITIALIZER, NgModule } from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { CoreModule } from './core/core.module';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { environment } from '../environments/environment';
import { DataTablesModule } from 'angular-datatables';
import { registerLocaleData } from '@angular/common';
import ae from '@angular/common/locales/ar-AE';

registerLocaleData(ae, 'ar-AE');

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: environment.keycloak.authority,
        realm: environment.keycloak.realm,
        clientId: environment.keycloak.clientId,
      },
      initOptions: {
        redirectUri:
          window.location.origin +
          window.location.pathname +
          '?token=eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJtb2Rlcm5AZ3JvY2VyeS5jb20iLCJjb250YWN0IjoiKzQwNzUxNjUzNTgzIiwibmFtZSI6Ik1PREVSTiBTQU1FRVJBIEdST0NFUlkiLCJleHAiOjE3NjA0NjM4NDQsImlhdCI6MTcyNjc2ODY1NCwiZW50aXR5IjoiMDAwMTAwMDA5MyJ9.rqzOX7S4LBwe3YbzPc15FJlepRbGzJRLOlH1LhIZXYmMwVVllHPHoJFgRFbS8YkOZ5QRlmqAOBRZktHs0CzX6A',
        onLoad: 'login-required',
        checkLoginIframe: false,
      },
    });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    KeycloakAngularModule,
    CoreModule,
    DataTablesModule,
  ],
  providers: [
    provideClientHydration(),
    provideAnimationsAsync(),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
