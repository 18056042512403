import { Inject, Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
  constructor(@Inject(MatSnackBar) private snackBar: MatSnackBar) {}
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.headers.get('skip')?.toString() === 'true') {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ProgressEvent) {
          this.snackBar.open(
            'Cannot connect to server, please try again later',
            '',
            {
              duration: 5000,
              horizontalPosition: 'left',
              verticalPosition: 'bottom',
              panelClass: ['danger'],
            }
          );
        } else if (
          error instanceof HttpErrorResponse &&
          error.error instanceof Blob
        ) {
          const reader = new FileReader();
          reader.onload = (e: Event) => {
            const errorDetail = (e.target as any).result
              ? JSON.parse((e.target as any).result)
              : null;
            this.snackBar.open(errorDetail?.message, '', {
              duration: 5000,
              horizontalPosition: 'left',
              verticalPosition: 'bottom',
              panelClass: ['danger'],
            });
          };
          reader.readAsText(error.error);
        } else {
          switch (error.status) {
            case 405: {
              this.snackBar.open('Not Allowed', '', {
                duration: 5000,
                horizontalPosition: 'left',
                verticalPosition: 'bottom',
                panelClass: ['danger'],
              });
              break;
            }
            case 500: {
              this.snackBar.open(
                error.error?.message ||
                  error.error?.detail ||
                  error.error?.error,
                '',
                {
                  duration: 5000,
                  horizontalPosition: 'left',
                  verticalPosition: 'bottom',
                  panelClass: ['danger'],
                }
              );
              break;
            }
            case 0: {
              this.snackBar.open(
                'Backend not available or request failed',
                '',
                {
                  duration: 5000,
                  horizontalPosition: 'left',
                  verticalPosition: 'bottom',
                  panelClass: ['danger'],
                }
              );
              break;
            }
            default:
              this.snackBar.open(
                error.error?.detail || error.error?.message,
                '',
                {
                  duration: 5000,
                  horizontalPosition: 'left',
                  verticalPosition: 'bottom',
                  panelClass: ['danger'],
                }
              );
          }
        }
        return throwError(() => error);
      })
    );
  }
}
