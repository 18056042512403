import { Component, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrl: './pie-chart.component.scss',
})
export class PieChartComponent implements OnInit {
  chartOption: EChartsOption;
  ngOnInit(): void {
    this.chartOption = {
      tooltip: {
        trigger: 'item',
        formatter: '{b}: {c} ({d}%)',
      },
      legend: {
        orient: 'horizontal',
        bottom: '5%',
        icon: 'circle',
        itemWidth: 10,
        itemHeight: 10,
        textStyle: {
          fontSize: 14,
        },
      },
      series: [
        {
          type: 'pie',
          radius: ['40%', '70%'],
          center: ['50%', '45%'],
          avoidLabelOverlap: false,
          label: {
            show: true,
            position: 'center',
            formatter: `65%/35%`,
            fontSize: 20,
            fontWeight: 'bold',
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 20,
              fontWeight: 'bold',
            },
          },
          labelLine: {
            show: false,
          },
          data: [
            {
              value: 75,
              name: 'Digital Payments',
              itemStyle: {
                color: '#07CC00',
              },
            },
            {
              value: 25,
              name: 'Cash payments',
              itemStyle: {
                color: '#D66C6B',
              },
            },
          ],
          animation: true,
          animationDuration: 1000,
          animationEasing: 'cubicInOut',
        },
      ],
    };
  }
}
