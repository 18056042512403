import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainLayoutModule } from '../layout/main-layout/main-layout.module';
import { UnsubscribeOnDestroyComponent } from './components/unsubscribe-on-destroy/unsubscribe-on-destroy.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandlingInterceptor } from './interceptors/error-handling/error-handling.interceptor';

@NgModule({
  declarations: [UnsubscribeOnDestroyComponent],
  imports: [CommonModule, MainLayoutModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlingInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {}
