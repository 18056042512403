<div mat-menu-item [ngClass]="status === 'NEW' ? 'unread' : ''">
    <div class="d-flex align-items-center flex-row py-2 text-black-50">
        <div class="content-container">
            <div>
                <p>
                    <b class="text-black">Client {{ name }}</b> with account ID <b class="text-black">{{ id }}</b
                    >.
                </p>
                <small>{{ date | timeAgo }}</small>
                <p class="small">Updated required documents</p>
            </div>
        </div>
    </div>
</div>
