<button
    mat-icon-button
    class="ml-1"
    matTooltip="Notifications"
    (menuClosed)="readAllNotifications()"
    (menuOpened)="onMenuOpened()"
    [matMenuTriggerFor]="notificationsMenu"
    [matBadge]="unreadNotifications.length"
    [matBadgeHidden]="!unreadNotifications.length"
>
    <mat-icon>notifications_none</mat-icon>
</button>

<mat-menu class="notifications-menu" #notificationsMenu="matMenu" [overlapTrigger]="false">
    <div
        class="d-flex flex-row gap-3 justify-content-between align-items-center px-3 py-2 position-sticky top-0 z-1 bg-white"
    >
        <span class="text-black-50 fw-bold">Notifications</span>
        <span class="text-success" *ngIf="unreadNotifications.length > 0">{{ unreadNotifications.length }} new</span>
    </div>
    <div class="notifications-container" (scroll)="onScroll()" #scrollContainer>
        <ng-container *ngFor="let notification of displayedNotifications">
            <app-notification-item
                [content]="notification.message"
                [status]="notification.status"
                [name]="notification.clientName"
                [date]="notification.created"
                [id]="notification.account"
                [routerLink]="[routeMap['client'], notification.clientExternalId, 'details']"
            >
            </app-notification-item>
            <mat-divider></mat-divider>
        </ng-container>
    </div>
</mat-menu>
