import { CanActivateFn, Router } from '@angular/router';
import { Roles } from '../models/roles.model';
import { inject } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

export const roleGuardGuard =
  (roles: Roles[]): CanActivateFn =>
  () => {
    const kcService = inject(KeycloakService);
    const userRoles = kcService.getUserRoles();
    const router = inject(Router);

    if (roles.includes(Roles.COKE)) {
      if (userRoles.includes(Roles.COKE)) {
        return true;
      } else {
        router.navigateByUrl('/');
      }
    }

    if (roles.includes(Roles.ADMIN)) {
      if (userRoles.includes(Roles.ADMIN)) {
        return true;
      } else {
        router.navigateByUrl('/coke');
      }
    }

    return false;
  };
