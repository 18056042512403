import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'empty',
})
export class EmptyPipe implements PipeTransform {
  transform(val: any): any {
    return val === '' || val === null || val === undefined ? '-' : val;
  }
}
