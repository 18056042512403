import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeAgo',
})
export class TimeAgoPipe implements PipeTransform {
  transform(dateString: string): string {
    const date = new Date(dateString);
    const now = new Date();
    const diffInMs = now.getTime() - date.getTime();
    const diffInSeconds = Math.floor(diffInMs / 1000);

    const secondsInHour = 3600;
    const secondsInDay = 86400;

    if (diffInSeconds < secondsInHour) {
      // Less than an hour -> display in minutes
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    } else if (diffInSeconds < secondsInDay) {
      // Less than a day -> display in hours
      const hours = Math.floor(diffInSeconds / secondsInHour);
      return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    } else {
      // More than a day -> display in days
      const days = Math.floor(diffInSeconds / secondsInDay);
      return `${days} day${days !== 1 ? 's' : ''} ago`;
    }
  }
}
