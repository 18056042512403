import {
  Component,
  ElementRef,
  EventEmitter,
  Input, OnChanges,
  OnInit,
  Output, SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FileUploadConfiguration } from './file-upload.configuration';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.scss',
})
export class FileUploadComponent implements OnChanges {
  @Input() config: FileUploadConfiguration;
  @Input() files: File[] = [];
  @Output() uploadFilesEmitter: EventEmitter<File[]> = new EventEmitter<
    File[]
  >();

  @ViewChild('fileUpload') fileUpload: ElementRef;

  inputFileName: string;
  defaultConfig: FileUploadConfiguration = {
    chooseLabel: 'Choose',
  } as FileUploadConfiguration;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges(): void {
    this.config = { ...this.defaultConfig, ...this.config };
  }

  upload(): void {
    this.uploadFilesEmitter.emit(this.files);
  }

  uploadEvent(): void {
    if (this.fileUpload) this.fileUpload.nativeElement.click();
  }

  onFileSelected(event: any) {
    const files = event.dataTransfer
      ? event.dataTransfer.files
      : event.target.files;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (this.validate(file)) {
        file.objectURL = this.sanitizer.bypassSecurityTrustUrl(
          window.URL.createObjectURL(files[i])
        );
        if (!this.isMultiple()) {
          this.files = [];
        }
        this.files.push(files[i]);
      }
    }
  }

  removeFile(file: File) {
    let ix;
    if (this.files && -1 !== (ix = this.files.indexOf(file))) {
      this.files.splice(ix, 1);
      this.clearInputElement();
    }
  }

  validate(file: File) {
    for (const f of this.files) {
      if (
        f.name === file.name &&
        f.lastModified === file.lastModified &&
        f.size === f.size &&
        f.type === f.type
      ) {
        return false;
      }
    }
    return true;
  }

  clearInputElement() {
    this.fileUpload.nativeElement.value = '';
  }

  isMultiple(): boolean {
    return !!this.config.multiple;
  }
}
