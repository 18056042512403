<div class="file-upload-box">
    <h4>{{ config.title }}</h4>

    <div class="file-upload-content text-center" (keyup.enter)="uploadEvent()" (click)="uploadEvent()" tabindex="0">
        <div class="cloud-upload">
            <mat-icon>cloud_upload</mat-icon>
        </div>
        <p class="mb-0">Drag and drop a file here</p>
        <p>or</p>
        <button mat-raised-button color="primary">
            <mat-icon>add</mat-icon>
            {{ config.chooseLabel }}
        </button>
    </div>

    <input
        #fileUpload
        type="file"
        style="display: none"
        (change)="onFileSelected($event)"
        [(ngModel)]="inputFileName"
        [accept]="config.accept"
    />

    <div class="row mt-4" *ngIf="files?.length">
        <div class="col">
            <div *ngFor="let f of files">
                <div class="d-flex align-items-center">
                    <span>{{ f.name }}</span>
                    <button
                        mat-icon-button
                        color="primary"
                        [attr.title]="config.deleteButtonLabel ?? null"
                        (click)="removeFile(f)"
                    >
                        <mat-icon>clear</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div class="col text-right">
            <button mat-raised-button color="accent" [disabled]="config.isLoading" (click)="upload()">
                <mat-icon>cloud_upload</mat-icon>
                Upload
            </button>
        </div>
    </div>
</div>
