import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { NotificationComponents } from './index';
import { NotificationRoutingModule } from './notification-routing.module';

@NgModule({
  declarations: [...NotificationComponents],
  imports: [CommonModule, NotificationRoutingModule, SharedModule],
  exports: [...NotificationComponents],
})
export class NotificationModule {}
