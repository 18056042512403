import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BatchesRequest } from '../../modules/batch/models/batch.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private http: HttpClient) {}

  getNotifications(request: BatchesRequest): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/notifications?page=${request.page.pageNumber}&size=${request.page.pageSize}`
    );
  }

  getNotificationsByClientId(
    id: number,
    request: BatchesRequest
  ): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/notifications/${id}?page=${request.page.pageNumber}&size=${request.page.pageSize}`
    );
  }

  readAllNotifications(): Observable<any> {
    return this.http.post(`${environment.apiUrl}/read-all-notifications`, {});
  }
}
