import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NotificationStatus } from '../../models/constants';
import { NotificationService } from '../../../../shared/services/notification.service';

const NOTIFICATION_REQUEST = {
  page: {
    pageNumber: 0,
    pageSize: 5,
  },
};

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrl: './notification-list.component.scss',
})
export class NotificationListComponent implements OnInit, OnDestroy {
  displayedNotifications: any[] = [];
  unreadNotifications: any[] = [];
  openedDropdown = false;
  hasMoreNotifications = true;
  @ViewChild('scrollContainer', { static: false }) scrollContainer!: ElementRef;

  timer: any;
  loading = false;
  routeMap: any = {
    client: '/clients/',
  };

  notificationRequest: any = NOTIFICATION_REQUEST;

  constructor(public notificationService: NotificationService) {
    this.notificationService
      .getNotifications(this.notificationRequest)
      .subscribe((response: any) => {
        this.unreadNotifications = response.notificationDtoList.filter(
          (e: any) => e.status === NotificationStatus.NEW
        );
        this.displayedNotifications = response.notificationDtoList;
      });
  }

  ngOnInit() {
    setTimeout(() => {
      this.fetchNotifications();
    }, 60000);
  }

  loadNotifications() {
    if (this.loading || !this.hasMoreNotifications) return;
    this.loading = true;
    this.notificationService
      .getNotifications(this.notificationRequest)
      .subscribe(
        (data) => {
          this.displayedNotifications = [
            ...this.displayedNotifications,
            ...data.notificationDtoList,
          ];
          this.hasMoreNotifications =
            data.totalElements > this.displayedNotifications.length;
          this.loading = false;
          this.notificationRequest.page.pageNumber++;
        },
        (error) => {
          this.loading = false;
          console.error('Failed to load notifications', error);
        }
      );
  }

  fetchNotifications() {
    this.notificationRequest = NOTIFICATION_REQUEST;
    if (!this.openedDropdown) {
      this.notificationService
        .getNotifications(this.notificationRequest)
        .subscribe((response: any) => {
          this.unreadNotifications = response.notificationDtoList.filter(
            (e: any) => e.status === NotificationStatus.NEW
          );
          this.displayedNotifications = response.notificationDtoList;
        });
      this.timer = setTimeout(() => {
        this.fetchNotifications();
      }, 60000);
    }
  }

  readAllNotifications() {
    this.openedDropdown = false;
    this.notificationService.readAllNotifications().subscribe({
      next: (response: any) => {
        this.unreadNotifications = [];
      },
      error: (error) =>
        console.error('Error marking notifications as read:', error),
    });
  }

  onMenuOpened() {
    this.openedDropdown = true;
    this.notificationRequest.page.pageNumber++;
  }

  onScroll() {
    const scrollElement = this.scrollContainer.nativeElement;
    const atBottom =
      scrollElement.scrollHeight - scrollElement.scrollTop <=
      scrollElement.clientHeight + 10;
    if (atBottom) {
      this.loadNotifications();
    }
  }

  ngOnDestroy() {
    clearTimeout(this.timer);
  }
}
