<div style="height: 100vh; overflow: hidden">
    <mat-toolbar color="primary">
        <mat-toolbar-row>
            <button
                mat-icon-button
                (click)="sidenav.toggle()"
                (keydown)="sidenav.toggle()"
                role="button"
                tabindex="-1"
                fxShow="true"
                fxHide.gt-sm
            >
                <mat-icon>menu</mat-icon>
            </button>
            <span>
                <a [routerLink]="['/']">
                    <img style="max-height: 40px" src="/assets/images/logo.svg" alt="FinGuard" />
                </a>
            </span>
            <span class="menu-spacer"></span>
            <div fxShow="true" fxHide.lt-md>
                <!-- The following menu items will be hidden on both SM and XS screen sizes -->
                <a
                    class="navbar-link mat-primary"
                    [routerLink]="['/dashboard']"
                    routerLinkActive="mat-accent"
                    mat-button
                    >Dashboard</a
                >
                <a class="navbar-link mat-primary" [routerLink]="['/batch']" routerLinkActive="mat-accent" mat-button
                    >Batch Management</a
                >
                <a class="navbar-link mat-primary" [routerLink]="['/clients']" routerLinkActive="mat-accent" mat-button
                    >Clients</a
                >
                <a
                    class="navbar-link mat-primary"
                    [routerLink]="['/scoring-configuration']"
                    routerLinkActive="mat-accent"
                    mat-button
                    >Credit Scoring</a
                >
                <a
                    class="navbar-link mat-primary"
                    [routerLink]="['/notifications']"
                    routerLinkActive="mat-accent"
                    mat-button
                    >Notifications</a
                >
            </div>
            <div fxFlex fxLayoutAlign="flex-end center">
                <app-notification-list></app-notification-list>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>

    <mat-sidenav-container fxFlexFill>
        <mat-sidenav #sidenav>
            <mat-nav-list>
                <a (click)="sidenav.toggle()" (keydown)="sidenav.toggle()" role="button" tabindex="-1" mat-list-item
                    >Close</a
                >
                <a [routerLink]="['/dashboard']" mat-list-item>Dashboard</a>
                <a [routerLink]="['/batch']" mat-list-item>Batch Management</a>
                <a [routerLink]="['/clients']" mat-list-item>Clients</a>
                <a [routerLink]="['/scoring-configuration']" mat-list-item>Credit Scoring</a>
            </mat-nav-list>
        </mat-sidenav>
        <mat-sidenav-content fxFlexFill>
            <div class="main-content container-fluid">
                <router-outlet></router-outlet>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
