import { Component, Input, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';

@Component({
  selector: 'app-chart-line',
  templateUrl: './chart-line.component.html',
  styleUrl: './chart-line.component.scss',
})
export class ChartLineComponent implements OnInit {
  @Input() xAxisData: string[];
  @Input() yAxisData: number[];
  chartOption: EChartsOption;

  ngOnInit(): void {
    this.chartOption = {
      backgroundColor: 'transparent',
      grid: {
        top: 5,
        right: 5,
        bottom: 5,
        left: 5,
        containLabel: false,
      },
      xAxis: {
        type: 'category',
        show: false,
        data: this.xAxisData,
      },
      yAxis: {
        type: 'value',
        show: false,
      },
      series: [
        {
          data: this.yAxisData,
          type: 'line',
          smooth: true,
          symbol: 'none',
          lineStyle: {
            width: 1,
            color: '#07CC00',
          },
          areaStyle: {
            opacity: 0.1,
            color: '#07CC00',
          },
        },
      ],
      tooltip: {
        show: false,
      },
      animation: true,
    };
  }
}
