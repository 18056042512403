import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationItemComponent } from './notification-item/notification-item.component';
import { NotificationListComponent } from './notification-list/notification-list.component';
import {
  MatMenu,
  MatMenuItem,
  MatMenuModule,
  MatMenuTrigger,
} from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatDivider } from '@angular/material/divider';
import { MatBadge } from '@angular/material/badge';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [NotificationItemComponent, NotificationListComponent],
  imports: [
    CommonModule,
    MatMenuItem,
    MatIcon,
    MatIconButton,
    MatTooltip,
    MatDivider,
    MatMenu,
    MatBadge,
    MatMenuTrigger,
    MatMenuModule,
    RouterModule,
    MatButton,
    SharedModule,
  ],
  exports: [NotificationListComponent],
})
export class NotificationModule {}
