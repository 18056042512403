import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrl: './notification-item.component.scss',
})
export class NotificationItemComponent {
  @Input() content: string;
  @Input() status: string;
  @Input() id: string;
  @Input() date: string;
  @Input() name: string;
}
