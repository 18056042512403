import { Component, OnInit } from '@angular/core';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { UnsubscribeOnDestroyComponent } from '../../../../core/components/unsubscribe-on-destroy/unsubscribe-on-destroy.component';
import { NotificationService } from '../../../../shared/services/notification.service';
import { EmptyPipe } from '../../../../shared/pipes/empty/empty.pipe';
import { BatchesRequest } from '../../../batch/models/batch.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss',
})
export class NotificationsComponent
  extends UnsubscribeOnDestroyComponent
  implements OnInit
{
  notificationsRequest: BatchesRequest = {
    page: {
      pageNumber: 0,
      pageSize: 1,
    },
  };
  notificationsOptions: ADTSettings = {};
  notificationsResponse: any;

  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private emptyPipe: EmptyPipe
  ) {
    super();
  }

  ngOnInit(): void {
    this.initTable();
  }

  initTable(): void {
    this.notificationsOptions = {
      serverSide: true,
      searching: false,
      ordering: false,
      columnDefs: [{ className: 'dt-left', targets: '_all' }],
      ajax: (dataTablesParams: any, callback) => {
        this.notificationsRequest.page.pageNumber =
          dataTablesParams.start / dataTablesParams.length;
        this.notificationsRequest.page.pageSize = dataTablesParams.length;

        this.notificationService
          .getNotifications(this.notificationsRequest)
          .subscribe({
            next: (data: any) => {
              this.notificationsResponse = data;
              callback({
                recordsTotal: data.totalElements,
                recordsFiltered: data.totalElements,
                data: data.notificationDtoList,
              });
            },
          });
      },
      columns: [
        {
          title: 'Client ID',
          data: 'account',
          ngPipeInstance: this.emptyPipe,
          orderable: false,
        },
        {
          title: 'Client Name',
          data: 'clientName',
          ngPipeInstance: this.emptyPipe,
          orderable: false,
        },
        {
          title: 'Message', //Internal Credit Limit
          data: 'message',
          ngPipeInstance: this.emptyPipe,
          orderable: false,
        },
        {
          title: 'Status', //Internal Credit Limit
          data: 'status',
          ngPipeInstance: this.emptyPipe,
          orderable: false,
        },
        {
          title: 'Type', //Internal Credit Limit
          data: 'type',
          ngPipeInstance: this.emptyPipe,
          orderable: false,
        },
        {
          title: '',
          data: null,
          render: () => {
            let content = '<div class="d-flex justify-content-end">';
            content += `<button type="button" class="btn btn-light dt-link"><i class="bi bi-arrow-right dt-link"></i></button>`;
            content += '</div>';

            return content;
          },
        },
      ],
      rowCallback: (row: Node, notification: any) => {
        $('td', row).off('click');
        $('td', row).on('click', (event) => {
          if (event.target.classList.contains('dt-link')) {
            this.router.navigate([
              '/clients',
              notification.clientExternalId,
              'details',
            ]);
          }
        });
        return row;
      },
    };
  }
}
