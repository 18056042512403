import { NgModule } from '@angular/core';
import {
  CommonModule,
  CurrencyPipe,
  DatePipe,
  TitleCasePipe,
} from '@angular/common';
import {
  MaterialModules,
  SharedComponents,
  SharedModules,
  SharedPipes,
} from './index';
import { provideNativeDateAdapter } from '@angular/material/core';
import { EmptyPipe } from './pipes/empty/empty.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { NgxEchartsModule } from 'ngx-echarts';

@NgModule({
  declarations: [SharedComponents, SharedPipes, TimeAgoPipe],
  imports: [
    CommonModule,
    SharedModules,
    MaterialModules,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
  ],
  exports: [
    SharedModules,
    MaterialModules,
    SharedComponents,
    EmptyPipe,
    TimeAgoPipe,
  ],
  providers: [
    provideNativeDateAdapter(),
    SharedPipes,
    DatePipe,
    TitleCasePipe,
    CurrencyPipe,
  ],
})
export class SharedModule {}
