import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainLayoutComponents } from './index';
import { SharedModule } from '../../shared/shared.module';
import { NotificationModule } from '../../modules/notification/notification.module';

@NgModule({
  declarations: [MainLayoutComponents],
  imports: [CommonModule, SharedModule, NotificationModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MainLayoutModule {}
