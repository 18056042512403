import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainContentComponent } from './layout/main-layout/components/main-content/main-content.component';

const routes: Routes = [
  {
    path: '',
    component: MainContentComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./modules/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'batch',
        loadChildren: () =>
          import('./modules/batch/batch.module').then((m) => m.BatchModule),
      },
      {
        path: 'clients',
        loadChildren: () =>
          import('./modules/clients/clients.module').then(
            (m) => m.ClientsModule
          ),
      },
      {
        path: 'scoring-configuration',
        loadChildren: () =>
          import(
            './modules/scoring-configuration/scoring-configuration.module'
          ).then((m) => m.ScoringConfigurationModule),
      },
    ],
  },
  {
    path: 'coke',
    loadChildren: () =>
      import('./modules/coke/coke.module').then((m) => m.CokeModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
